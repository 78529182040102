/* Add these styles to your App.css file */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  position: relative;
  margin: 50px auto;
  padding: 20px;
  background: white;
  width: 90%;
  height: 90%;
  overflow: hidden;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.expand-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-right: 270px;
}

.start-button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.nav-button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .rank-table thead tr th {
    position: sticky;
    top: 0;
    background-color: #ffff;
  }
}