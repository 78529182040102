.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  /* color: #fff; */
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  /* Use half of the width (120/2 = 60), to center the tooltip */
}

h3 {
  font-weight: 500;
}

/* @keyframes blinkingYellow {
  0% {
    background-color: green;
  }

  100% {
    background-color: transparent;
  }
} */


.dr-n-table {
  border-radius: 10px;
  /* border: #eee solid 1px; */
  margin-bottom: 10px;
  /* border: none; */
}

.dr-n-table h3 {
  /* justify-content: center; */
  /* align-items: center; */
  margin: 0;
  /* margin-bottom: 10px; */
  border-radius: 10px 10px 0 0;
  color: white;
  background-color: #004080;
  text-align: center;
}


.dr-n-table table thead th {
  /* background: #eee; */
  border: none;
  font-weight: 600;
  text-align: center;
}

/* .all-contents {
  height: 100%;
} */




/* .main-content {
  display: flex;
  margin-top: 10px;
  margin-left: 50px;
} */

.each-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  /* height: 100%; */
}

.right-block {
  display: flex;
  justify-content: center;
}

.right-content2 {
  display: none;
}

/* .all-chart {
  width: 55%;
  margin-left: 5px;
  margin-right: 5px;
} */

@media screen and (max-width: 1200px) {

  /* .all-chart {
    width: 50%;
  } */


  /* .left-block {
    display: none;
  } */


  /* .main-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  } */

  .big-block {
    /* width: 200px; */
    margin-bottom: 20px;
  }

  /* .right-block {
    display: block;
  } */


  .right-content2 {
    display: block;
    width: 200px
  }

  .dr-n-table table {
    font-size: 13px;
    border-radius: 0 0 10px 10px;
    border-top: 0;
    /* background: rgb(248, 249, 250); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    /* padding: 0px 0px 5px 0px; */
  }
}

@media screen and (max-width: 600px) {

  /* .all-chart {
    width: 90%;
  } */

  .left-block {
    display: none;
  }



  .right-block {
    /* display: inline-block; */
    display: block;
    width: 200px;
    /* flex-direction: column; */
    /* position: absolute; */
    /* right: 0; */
    /* top: 0; */
    /* height: 100%; */
  }

  .big-block {
    display: block;
    width: 200px;
    /* margin-right: 10px; */
  }

  .all-item-table {
    display: block;
    /* width: 200px; */
  }


  .all-contents {
    /* width: 100%; */
    /* justify-content: center; */
    /* display: flex; */
    display: flex;
    justify-content: flex-start;
    /* justify-content: space-between; */
    /* align-items: flex-start; */
  }
}