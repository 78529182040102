@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto:ital,wght@0,100..900;1,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */

}

/* ตั้งค่าพื้นหลัง */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #2E2E2E;
    position: relative;
    /* ให้พื้นหลังใช้ position แบบ relative */
    overflow: hidden;
    /* ป้องกันการเลื่อนหน้าจอ */
}

/* กล่อง login */
/* .login-box {
    position: fixed;
    display: flex;
    background-color: #202431;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.3);
    width: 1000px;
    height: 600px;
} */

/* shape */

.shape1 {
    position: fixed;
    width: 100px;
    height: 40px;
    background-color: #202431;
    z-index: 9999;
    border-radius: 30px;
    margin-right: 1000px;
    margin-top: 450px;

}

.shape2 {
    position: fixed;
    width: 100px;
    height: 40px;
    background-color: #727272;
    z-index: 9999;
    border-radius: 30px;
    margin-right: 1000px;
    margin-top: 370px;

}

.shape3 {
    position: fixed;
    width: 200px;
    height: 40px;
    background-color: #202431;
    z-index: 9999;
    border-radius: 30px;
    margin-right: 1000px;
    margin-top: 290px;

}

.shape4 {
    position: fixed;
    width: 40px;
    height: 40px;
    background-color: #202431;
    z-index: 9999;
    border-radius: 30px;
    margin-right: 1270px;
    margin-top: 290px;

}

.shape5 {
    position: fixed;
    width: 100px;
    height: 40px;
    background-color: #2E2E2E;
    z-index: 9999;
    border-radius: 30px;
    margin-left: 1000px;
    margin-bottom: 370px;

}

.shape6 {
    position: fixed;
    width: 100px;
    height: 40px;
    background-color: #202431;
    z-index: 9999;
    border-radius: 30px;
    margin-left: 1000px;
    margin-bottom: 290px;

}

.shape7 {
    position: fixed;
    width: 200px;
    height: 40px;
    background-color: #202431;
    z-index: 9999;
    border-radius: 30px;
    margin-left: 1000px;
    margin-bottom: 450px;

}

.back1 {
    position: fixed;
    width: 1000px;
    height: 1000px;
    background-color: #727272;
    border-radius: 1000px;
    margin-right: 1100px;
    margin-top: 850px;
}

.back2 {
    position: fixed;
    width: 2000px;
    height: 2000px;
    background-color: #727272;
    border-radius: 1000px;
    margin-right: 700px;
    margin-bottom: 2500px;
    opacity: 30%;
}

.back3 {
    position: fixed;
    width: 1800px;
    height: 1800px;
    background-color: #727272;
    border-radius: 1000px;
    margin-left: 1300px;
    margin-bottom: 2300px;
}

/* ซ้าย: Logo */
/* .left-section {
    background-color: #202431;
    padding: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
} */

.logo {
    display: flex;
    align-items: center;
    /* จัดแนวให้ภาพและข้อความอยู่กลางในแนวตั้ง */
    justify-content: center;
    /* จัดแนวให้อยู่กลางในแนวนอน */
    font-family: 'Arial', sans-serif;
    /* กำหนดฟอนต์ */
    font-size: 2rem;
    /* ขนาดตัวอักษร */
    color: #333;
    /* สีตัวอักษร */
    text-transform: uppercase;
    /* ทำให้ข้อความเป็นตัวพิมพ์ใหญ่ */
    letter-spacing: 2px;
    /* เพิ่มระยะห่างระหว่างตัวอักษร */
}

.logo img {
    /* width: 400px; */
    width: 100%;
    /* กำหนดขนาดของโลโก้ */
    height: auto;
    margin-right: -80px;
    /* เว้นระยะห่างจากข้อความ */
    margin-top: -50px;

}

.highlight {
    color: #5ad9ff;
}

.subtext {
    background: linear-gradient(90deg, #febd4c, #8655f8);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    margin-left: 50px;
    margin-top: -20px;
}

/* ขวา: Form */
/* .right-section {
    background-color: #202431;
    padding: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    margin-top: -140px;
} */

/* .right-section h2 {
    color: #ffffff;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 40px;
    background: linear-gradient(90deg, #FFCD77, #5DD1FF);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'nunito'

} */

.inputBox,
.inputBox1 {
    position: relative;
    width: 240px;
    margin-bottom: 30px;
    margin-left: 10px;
}

.inputBox input,
.inputBox1 input {
    width: 300px;
    height: 50px;
    padding: 10px;
    outline: none;
    border: none;
    color: #ffffff;
    font-size: 1.1em;
    margin-left: 40px;
    background: transparent;
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transition: 0.1s;
    border-bottom-left-radius: 8px;
}

.inputBox span,
.inputBox1 span {

    position: absolute;
    left: 0;
    transform: translateY(-8px);
    margin-left: 50px;
    margin-top: -35px;
    padding: 10px;
    pointer-events: none;
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 3px;
    border-radius: 8px;
    font-family: 'nunito'
}

.inputBox input:valid~span,
.inputBox input:focus~span {
    transform: translateX(113px) translateY(-15px);
    font-size: 0.8em;
    padding: 10px 10px;
    background: #ffffff;
    letter-spacing: 0.2em;
    color: #fff;
    border: 2px;
    font-family: 'nunito'

}

.inputBox input:valid~span,
.inputBox input:focus~span,
.inputBox1 input:valid~span,
.inputBox1 input:focus~span {
    transform: translateX(170px) translateY(-30px);
    font-size: 0.8em;
    padding: 3px 10px;
    background: #ffffff;
    letter-spacing: 0.2em;
    color: #000000;
    border: 2px;
    font-family: 'nunito'
}

.inputBox input:valid,
.inputBox input:focus,
.inputBox1 input:valid,
.inputBox1 input:focus {
    border: 2px solid #ffffff;
    border-radius: 8px;
}




.options {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 12px;
    margin-bottom: 15px;
    color: #bbbbbb;
}

.options a {
    background: linear-gradient(90deg, #FFCD77, #7197ff);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    margin-top: -20px;
    margin-right: 21px;
}



/* checkbox */
.checkbox-wrapper {
    --checkbox-size: 16px;
    --checkbox-color: #00ff88;
    --checkbox-shadow: rgba(0, 255, 136, 0.3);
    --checkbox-border: rgba(0, 255, 136, 0.7);
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 10px;
    margin-left: 20px;
    margin-top: -20px;
}

.checkbox-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-wrapper .checkmark {
    position: relative;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    border: 2px solid var(--checkbox-border);
    border-radius: 8px;
    transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px var(--checkbox-shadow);
    overflow: hidden;
}

.checkbox-wrapper .checkmark::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, var(--checkbox-color), #00ffcc);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    transform: scale(0) rotate(-45deg);
}

.checkbox-wrapper input:checked~.checkmark::before {
    opacity: 1;
    transform: scale(1) rotate(0);
}

.checkbox-wrapper .checkmark svg {
    width: 0;
    height: 0;
    color: #1a1a1a;
    z-index: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
}

.checkbox-wrapper input:checked~.checkmark svg {
    width: 18px;
    height: 18px;
}

.checkbox-wrapper:hover .checkmark {
    border-color: var(--checkbox-color);
    transform: scale(1.1);
    box-shadow:
        0 0 20px var(--checkbox-shadow),
        0 0 40px var(--checkbox-shadow),
        inset 0 0 10px var(--checkbox-shadow);
    transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.checkbox-wrapper input:checked~.checkmark {
    animation: pulse 1s cubic-bezier(0.25, 0.1, 0.25, 1);
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 15px var(--checkbox-shadow);
    }

    50% {
        transform: scale(0.95);
        box-shadow:
            0 0 25px var(--checkbox-shadow),
            0 0 40px var(--checkbox-shadow);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 15px var(--checkbox-shadow);
    }
}

.checkbox-wrapper .label {
    margin-left: 10px;
    font-family: "Segoe UI", sans-serif;
    color: var(--checkbox-color);
    font-size: 14px;
    text-shadow: 0 0 10px var(--checkbox-shadow);
    opacity: 0.9;
    transition: all 0.3s ease;
}

.checkbox-wrapper:hover .label {
    opacity: 1;
    transform: translateX(10px);
}



.color-7 {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    margin: 20px;
    margin-left: 480px;
    margin-top: 240px;
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    position: relative;
    z-index: 9999;

}

.color-7:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.color-7r:focus {
    outline: none;
}

.color-7 {
    background-image: linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #F24645);
    box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}

.signup-text {
    text-align: center;
    margin-top: 390px;
    color: #bbbbbb;
    margin-left: -225px;
    position: relative;
    z-index: 9999;
}

.signup-text a {
    color: #fca311;
    text-decoration: none;
}


.showpass {
    margin-left: 350px;

}







/* register */


/* ตั้งค่าพื้นหลัง */
.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #2E2E2E;

}

/* กล่อง login */
/* .register-box {
    position: fixed;
    display: flex;
    background-color: #202431;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.3);
    width: 1100px;
    height: 630px;
} */

/* shape */

.shape11 {
    position: fixed;
    width: 100px;
    height: 40px;
    background-color: #202431;
    z-index: 9999;
    border-radius: 30px;
    margin-right: 1100px;
    margin-top: 450px;

}

.shape22 {
    position: fixed;
    width: 100px;
    height: 40px;
    background-color: #727272;
    z-index: 9999;
    border-radius: 30px;
    margin-right: 1100px;
    margin-top: 370px;

}

.shape33 {
    position: fixed;
    width: 170px;
    height: 40px;
    background-color: #202431;
    z-index: 9999;
    border-radius: 30px;
    margin-right: 1130px;
    margin-top: 290px;

}

.shape44 {
    position: fixed;
    width: 40px;
    height: 40px;
    background-color: #202431;
    z-index: 9999;
    border-radius: 30px;
    margin-right: 1370px;
    margin-top: 290px;

}

.shape55 {
    position: fixed;
    width: 100px;
    height: 40px;
    background-color: #2E2E2E;
    z-index: 9999;
    border-radius: 30px;
    margin-left: 1100px;
    margin-bottom: 370px;

}

.shape66 {
    position: fixed;
    width: 100px;
    height: 40px;
    background-color: #202431;
    z-index: 9999;
    border-radius: 30px;
    margin-left: 1100px;
    margin-bottom: 290px;

}

.shape77 {
    position: fixed;
    width: 200px;
    height: 40px;
    background-color: #202431;
    z-index: 9999;
    border-radius: 30px;
    margin-left: 1100px;
    margin-bottom: 450px;

}

.backg1 {
    position: fixed;
    width: 1000px;
    height: 1000px;
    background-color: #727272;
    border-radius: 1000px;
    margin-right: 1100px;
    margin-top: 850px;
}

.backg2 {
    position: fixed;
    width: 2000px;
    height: 2000px;
    background-color: #727272;
    border-radius: 1000px;
    margin-right: 700px;
    margin-bottom: 2500px;
    opacity: 30%;
}

.backg3 {
    position: fixed;
    width: 1800px;
    height: 1800px;
    background-color: #727272;
    border-radius: 1000px;
    margin-left: 1300px;
    margin-bottom: 2300px;
}

/* left: Form */
/* .left-sections {
    background-color: #202431;
    padding: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;
} */

/* .left-sections h2 {
    color: #ffffff;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-right: 250px;
    background: linear-gradient(90deg, #FFCD77, #3c4ef6);
    background-clip: text;
    -webkit-text-fill-color: transparent;

} */

/* .input-containers {
    display: flex;
    
    justify-content: center;
    
    margin-right: 250px;
    margin-top: 5px;
} */

.inputBoxs,
.inputBoxs {
    position: relative;
    width: 240px;
    margin-bottom: 20px;

}

.inputBoxs input,
.inputBoxs input {
    width: 200px;
    height: 40px;
    padding: 10px;
    outline: none;
    border: none;
    color: #ffffff;
    font-size: 1em;
    margin-left: 36px;
    background: transparent;
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transition: 0.1s;
    border-bottom-left-radius: 8px;
}

.inputBoxs span,
.inputBoxs span {

    position: absolute;
    left: 0;
    transform: translateY(30px);
    margin-left: 50px;
    margin-top: -35px;
    padding: 10px;
    pointer-events: none;
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 3px;
    border-radius: 8px;
}

.inputBoxs input:valid~span,
.inputBoxs input:focus~span {
    transform: translateX(113px) translateY(-15px);
    font-size: 0.8em;
    padding: 10px 10px;
    background: #ffffff;
    letter-spacing: 0.2em;
    color: #fff;
    border: 2px;
}

.inputBoxs input:valid~span,
.inputBoxs input:focus~span,
.inputBoxs input:valid~span,
.inputBoxs input:focus~span {
    transform: translateX(80px) translateY(20px);
    font-size: 0.8em;
    padding: 3px 10px;
    background: #ffffff;
    letter-spacing: 0.2em;
    color: #000000;
    border: 2px;
}

.inputBoxs input:valid,
.inputBoxs input:focus,
.inputBoxs input:valid,
.inputBoxs input:focus {
    border: 2px solid #ffffff;
    border-radius: 8px;
}

.options .package {
    /* background: #ffffff ; */
    color: #000000;
    width: 260px;
    height: 50px;
    border-radius: 30px;
    margin-right: 300px;
    margin-top: -5px;
    display: flex;
    border: 1px solid #ffffff;
    box-shadow: #3c4ef6 0px 2px 2px 0px;
}


/* .package1 h3 {
    color: #ffffff;
    text-align: start;
    margin-top: -15px;
    margin-left: 70px;
    font-size: 1.3rem;
} */

/* From  */
.uv-checkbox-wrapper {
    display: inline-block;
    margin-top: 11px;
    margin-left: 30px;
    display: flex;
    gap: 30px;
}

.uv-checkbox {
    display: none;
}

.uv-checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.uv-checkbox-icon {
    position: relative;
    width: 2em;
    height: 2em;
    border: 2px solid #ccc;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    transition: border-color 0.3s ease, border-radius 0.3s ease;
}

.uv-checkmark {
    position: absolute;
    top: 0.1em;
    left: 0.1em;
    width: 1.6em;
    height: 1.6em;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 24;
    stroke-dashoffset: 24;
    transition: stroke-dashoffset 0.5s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.uv-checkbox-text {
    margin-left: 0.5em;
    transition: color 0.3s ease;
    color: #ffffff;
    font-size: 1rem;
}

.uv-checkbox:checked+.uv-checkbox-label .uv-checkbox-icon {
    border-color: #6c5ce7;
    border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
    background-color: #6c5ce7;
}

.uv-checkbox:checked+.uv-checkbox-label .uv-checkmark {
    stroke-dashoffset: 0;
}

.uv-checkbox:checked+.uv-checkbox-label .uv-checkbox-text {
    color: #ada4f0;
}

/* options */
.checkbox-container .totalprice {
    display: flex;
    flex-direction: column;
    background-color: #353535;
    color: #ffffff;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: #5162fc 0px 2px 2px 0px;
    border: 2px solid #ccc;
}

/* .checkbox-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    max-width: 500px;
    margin: auto;
    margin-top: 5px;
    margin-right: 240px;
} */

.checkbox-container .checkbox-price {
    font-size: 14px;
    color: #e5bf13;
    /* สีเหลือง */
    font-family: 'nunito';
    ;
}

.content22 {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    background-color: #2E2E2E;
    border-radius: 20px;
    padding: 5px 15px;
    z-index: 999px;
    box-shadow: #5162fc 0px 2px 2px 0px;
}

.full-width {
    grid-column: span 1;
    justify-content: start;
}

.text-desc {
    display: flex;
    flex-direction: column;
}

.desc {
    font-size: 12px;
    color: #e5bf13;
}

.text {
    color: white;
    text-decoration: none;
    /* เอาขีด-ออก */
    cursor: pointer;
    font-size: 14px;
    font-family: 'nunito'
}

.price {

    font-size: 16px;
    /* font-weight: bold; */
    color: #ffffff;
    /* สีข้อความปกติ */
    text-shadow: 2px 2px 5px #0b24ff;
    /* เพิ่มเงาของข้อความ */

}

.content22:hover {
    background-color: rgba(236, 232, 231, 0.1);
    border-radius: 16px;
}

.checkBox {
    display: block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0);
    border-radius: 30px;
    position: relative;
    box-shadow: 0px 0px 0px 2px #ffffff;
    transition: 200ms all;
    cursor: pointer;
    overflow: hidden;
}

.checkBox .transition {
    width: 60px;
    height: 60px;
    background-color: #e5bf13;
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
}

.checkBox input[type="checkbox"]:checked+div {
    left: -10px;
    top: -10px;
}

.checkBox input[type="checkbox"] {
    position: absolute;
    left: 50px;
    visibility: hidden;
}

.transition {
    transition: 300ms ease;
}

/* Button */
.button {
    position: relative;
    display: inline-block;
    padding: 16px 32px;
    border-radius: 14px;
    overflow: hidden;
    z-index: 1;
    border: 1px solid rgb(129, 78, 249);
    background: white;
    font-weight: bold;
    font-size: 14px;
    color: #1993e4;
    transition: all 1.2s linear;
    cursor: pointer;
}

.button::after {
    content: "";
    position: absolute;
    background: linear-gradient(to right, #1993e4, #1993e456);
    border-radius: 30%;
    height: 96px;
    width: 96px;
    animation: spin infinite linear 1.8s;
    animation-delay: 0;
    bottom: -38px;
    right: -112px;
    transition: all 1.2s linear;
    z-index: 0;
}

.button:hover {
    color: #fff;
    transition-duration: 1.2s;
}

.button:hover::after {
    right: -60px;
    bottom: -92px;
    width: 256px;
    height: 256px;
}

.button>.button-content {
    position: relative;
    z-index: 2;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* card */
/* From Uiverse.io by JohnnyCSilva */
/* .card {
    width: 500px;
    max-width: 430px;
    height: 60px;
    background: #353535;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: left;
    transition: 0.5s ease-in-out;
    margin-left: 50px;
    margin-top: 0px;
    box-shadow: #6253e1 0px 2px 2px 0px;
} */

.card:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    border-radius: 10px;
    background: linear-gradient(#d7cfcf, #9198e5);
    overflow: hidden;
    background-image: url('../../../images/kbank.png');
    background-size: cover;
    /* ให้รูปขยายตามขนาด */
    background-position: center;
    /* จัดตำแหน่งรูปให้อยู่ตรงกลาง */
}

.img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card:hover>.img {
    transition: 0.5s ease-in-out;
    background: linear-gradient(#9198e5, #712020);
    background-image: url('../../../images/kbank.png');
    background-size: cover;
    /* ให้รูปขยายตามขนาด */
    background-position: center;
    /* จัดตำแหน่งรูปให้อยู่ตรงกลาง */
}

.textBox {
    width: calc(100% - 90px);
    margin-left: 10px;
    color: white;
    font-family: 'nunito'
}

.textContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.span {
    font-size: 10px;
}

.h1 {
    font-size: 15px;
    font-weight: bold;
    font-family: 'nunito'
}

.h1 .span10 {
    color: #e5bf13;
}


.p {
    font-size: 12px;
    font-weight: lighter;
}

/* button login */
.buttonRegister {
    display: flex;
    margin-left: 90px;
    gap: -30px;
}

.btn-hover1 {
    width: 150px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    margin-left: 5px;
    margin-top: 20px;
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;

    border-radius: 50px;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover1:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover1:focus {
    outline: none;
}

.btn-hover1.color-7 {
    background-image: linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #F24645);
    box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}



.buttonRegister {
    display: flex;
    margin-left: 90px;
    gap: -30px;
}

.btn-hover2 {
    width: 150px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    margin-left: 10px;
    margin-top: 20px;
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;

    border-radius: 50px;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover2:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover2:focus {
    outline: none;
}

.btn-hover2.color-7 {
    background-image: linear-gradient(to right, #c6bff1, #912d2f, #A3A1FF, #F24645);
    box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}

@media screen and (max-width: 900px) {

    .inputBox span,
    .inputBox1 span,
    .inputBox,
    .inputBox1,
    .inputBox input,
    .inputBox1 input {
        margin-left: -20px;
        /* display: flex; */
        /* justify-content: center; */
        /* width: auto; */
        /* width: 50%; */
    }

    .inputBoxs span {
        margin-left: 0;
    }

    .inputBoxs input {
        margin-left: 0;
        width: 180px;
    }

    .options .package {
        margin-right: unset;
    }
}