.item-hints {
    --purple: #720c8f;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    padding-right: 170px;
    position: relative;
    z-index: 9999; /* เพิ่มค่านี้เพื่อให้มันอยู่ด้านหน้า */
  }
  .item-hints .hint {
    margin: 150px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-hints .hint-dot {
    z-index: 3;
    border: 2px solid #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-transform: translate(-0%, -0%) scale(0.95);
    transform: translate(-0%, -0%) scale(0.95);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: #e4b87d;
  }
  .item-hints .hint-radius {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -125px 0 0 -125px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .item-hints .hint[data-position="1"] .hint-content {
    top: 85px;
    left: 50%;
    margin-left: 56px;
  }
  .item-hints .hint-content {
    width: 150px;
    position: absolute;
    z-index: 5;
    top:-120px;
    padding: 35px 20px;
    opacity: 0;
    transition: opacity 0.7s ease, visibility 0.7s ease;
    color: #000;
    visibility: hidden;
    pointer-events: none;
    background-color: #f4dbbd; /* พื้นหลังขาวโปร่ง */
    border-radius: 10px; /* มุมโค้งมน */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* เพิ่มเงา */

    /* ทำให้เนื้อหากลางกล่อง */
    display: flex;
    justify-content: center; /* จัดตำแหน่งเนื้อหากลางในแนวนอน */
    align-items: center; /* จัดตำแหน่งเนื้อหากลางในแนวตั้ง */
    text-align: center; /* ข้อความให้อยู่ตรงกลาง */
  }
  .item-hints .hint:hover .hint-content {
    position: absolute;
    z-index: 5;
    padding: 35px 20px;
    opacity: 1;
    -webkit-transition: opacity 0.7s ease, visibility 0.7s ease;
    transition: opacity 0.7s ease, visibility 0.7s ease;
    color: #000;
    visibility: visible;
    pointer-events: none;
    align-items:center;
    justify-content:center;

    /* ทำให้เนื้อหากลางกล่อง */
    display: flex;
    justify-content: center; /* จัดตำแหน่งเนื้อหากลางในแนวนอน */
    align-items: center; /* จัดตำแหน่งเนื้อหากลางในแนวตั้ง */
    text-align: center; /* ข้อความให้อยู่ตรงกลาง */
  }
  .item-hints .hint-content::before {
    width: 0px;
    bottom: -10px;
    left: -10px;
    content: "";
    background-color: #32CD32;
    height: 3px;
    position: absolute;
    transition: width 0.4s;
  }
  .item-hints .hint:hover .hint-content::before {
    width: 150px;
    transition: width 0.4s;
  }
  .item-hints .hint-content::after {
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: rotate(-225deg);
    transform: rotate(-225deg);
    bottom: -10px;
    left: -10px;
    width: 50px;
    content: "";
    background-color: #32CD32;
    height: 3px;
    position: absolute;
    opacity: 1;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }
  .item-hints .hint:hover .hint-content::after {
    opacity: 1;
    visibility: visible;
  }
  .item-hints .hint[data-position="4"] .hint-content {
    bottom: 85px;
    left: 50%;
    margin-left: 56px;
  }
  