@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  /* font-family: "Mitr", "Open Sans", "Source Sans Pro", sans-serif; */
  font-family: "Lato";
}

/* body { */
  /* font-family: 'Mitr', sans-serif; */
/* } */

:root {
  --top-bar-height: 0px;
  /*80px*/
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}



.hide-pagination .MuiDataGrid-pagination,
.hide-pagination .MuiDataGrid-toolbar {
  display: none !important;
}





/* Track */
/* ::-webkit-scrollbar-track {
  background: #e0e0e0;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

::-webkit-scrollbar {
  width: '6px';
  height: '6px';
}


::-webkit-scrollbar-thumb {
  background-color: '#888';
  border-radius: '10px';
  background: 'linear-gradient(darkblue, skyblue)';
}


::-webkit-scrollbar-track {
  background-color: '#f1f1f1';
  border-radius: '10px';
}