/* .form-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  max-width: 400px;
  margin: 0;
  padding-left: -20px;
}

.form-row {
  display: flex;
  gap: 40px;
} */

.inputGroup {
  font-family: 'Lato', sans-serif;
  position: relative;
  /* flex: 1; */

}

.inputGroup.full-width input {
  max-width: 200px
  /* ให้ความกว้างเต็มที่ */
}


.inputGroup input {
  font-size: 1rem;
  padding: 0.8em;
  outline: none;
  border: 2px solid rgb(109, 108, 108);
  background-color: transparent;
  border-radius: 10px;
  max-width: 220px;
  height: 40px;
}

.inputGroup label {
  font-size: 1rem;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0.4em;
  margin-left: 0.5em;
  pointer-events: none;
  transition: all 0.3s ease;
  color: rgb(0, 0, 0);
}

.inputGroup :is(input:focus, input:valid)~label {
  transform: translateY(-58%) scale(0.9);
  margin: 0em;
  margin-left: 1.3em;
  padding: 0.2em;
  background-color: #ffffff;
}

.inputGroup :is(input:focus, input:valid) {
  border-color: rgb(0, 0, 0);
}


.form-container button {
  padding: 15px 40px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #0056b3;
}

/* ปุ่มที่มีการตกแต่งเพิ่มเติม */
.btn-hover {
  width: 150px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  /* margin: 20px;
  margin-left: 15px; */
  margin-top: -3px;
  height: 45px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 10px;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-3 {
  background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}