/* From Uiverse.io by mrhyddenn */ 
.loader {
    position: relative;
    width: 110px;
    height: 30px;
    background: #ffffff;
    border-radius: 100px;
    /* margin-left: 670px; */
    /* margin-top: 60px; */
  }
  
  .loader::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 10px;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 40px 0 0 20px #ffffff;
  }
  
  .snow {
    position: relative;
    display: flex;
    z-index: 1;
  }
  
  .snow span {
    position: relative;
    width: 3px;
    height: 3px;
    background: #ffffff;
    margin: 0 2px;
    border-radius: 50%;
    animation: snowing 5s linear infinite;
    animation-duration: calc(15s / var(--i));
    transform-origin: bottom;
  }
  
  @keyframes snowing {
    0% {
      transform: translateY(0px);
    }
  
    70% {
      transform: translateY(100px) scale(1);
    }
  
    100% {
      transform: translateY(100px) scale(0);
    }
  }
  