/* Add these styles to your App.css file */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  position: relative;
  margin: 50px auto;
  padding: 20px;
  background: white;
  width: 90%;
  height: 90%;
  overflow: hidden;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.expand-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.start-button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.nav-button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .rank-table thead {
    position: sticky;
    top: 0;
    background-color: #ffff;
    /* border: 1px solid #ddd; */
    /* z-index: 10000; */
  }
}

.nav-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}

.nav-button:active {
  transform: scale(0.98);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* สีของปุ่มแต่ละแบบ */
.start {
  background-color: #4CAF50;
  color: white;
}

.half-day {
  background-color: #FFA726;
  color: white;
}

.end-day {
  background-color: #E53935;
  color: white;
}

.show-all {
  background-color: #1976D2;
  color: white;
}

/* ปรับสีปุ่มเมื่อ hover */
.start:hover {
  background-color: #43A047;
}

.half-day:hover {
  background-color: #FB8C00;
}

.end-day:hover {
  background-color: #D32F2F;
}

.show-all:hover {
  background-color: #1565C0;
}

