/* src/components/Loading.css */
.center-lo {
    display: flex;
    align-items: center; /* จัดตำแหน่งในแนวตั้ง */
    margin-left: 20px; /* เพิ่มระยะห่างจาก title */
    margin-bottom: 20px;
  }
  
  .loging {
    width: 100px; /* ขนาดของ loading */
    height: 40px; /* ขนาดของ loading */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .loging div {
    background: blue;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 1;
    animation-name: loding;
    animation-duration: 1.5s; /* ลดเวลาแอนิเมชั่น */
    animation-iteration-count: infinite;
  }
  
  .loging #load1 {
    animation-delay: 0s;
    margin-left: 10px;
  }
  
  .loging #load2 {
    animation-delay: 0.2s;
    margin-left: 10px;
  }
  
  .loging #load3 {
    animation-delay: 0.4s;
    margin-left: 10px;
  }
  
  .loging #load4 {
    animation-delay: 0.6s;
    margin-left: 10px;
  }
  
  .loging #load5 {
    animation-delay: 0.8s;
    margin-left: 10px;
  }
  
  @keyframes loding {
    0% {
      transform: translateY(0); /* เริ่มที่ตำแหน่งปกติ */
    }
    50% {
      transform: translateY(10px); /* ยกขึ้น 10px */
      opacity: 0.5;
      background: red;
    }
    100% {
      transform: translateY(0); /* กลับลงมาเป็น 0 */
      opacity: 1;
      background: blue;
    }
  }
  